import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Notifications: Notifications_ReportService;
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Invoices: Invoices_ReportService;
    public FootPrintApiManager: FootPrintApiManager_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

