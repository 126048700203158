import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Document360_OperationService } from './Document360.operation.service';
import { Usersnap_OperationService } from './Usersnap.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.Document360 = this.injector.get(Document360_OperationService);
    this.Usersnap = this.injector.get(Usersnap_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_OperationService);
    this.FootPrintManager = this.injector.get(FootPrintManager_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public Notifications: Notifications_OperationService;
    public Document360: Document360_OperationService;
    public Usersnap: Usersnap_OperationService;
    public Invoices: Invoices_OperationService;
    public FootPrintApiManager: FootPrintApiManager_OperationService;
    public FootPrintManager: FootPrintManager_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
