<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="edit" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_edit_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="save" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_save_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator1" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="delete_rule" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator2" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="request_history" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_request_history_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-is_enabled" *ngIf="!fields.is_enabled.hidden" 
                            class="field-container full {{fields.is_enabled.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_enabled.styles.style"
                            [ngClass]="fields.is_enabled.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_enabled.label}}{{fields.is_enabled.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_enabled.label}}">{{fields.is_enabled.label}}<span *ngIf="fields.is_enabled.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_enabled"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_enabled.control.styles.style"
                                      [ngClass]="fields.is_enabled.control.styles.classes">{{fields.is_enabled.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_enabled.invalid">
                          <ng-container *ngFor="let error of fields.is_enabled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Notifications-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Notifications-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Notifications-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Notifications-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-context" *ngIf="!fields.context.hidden" 
                            class="field-container full {{fields.context.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.context.styles.style"
                            [ngClass]="fields.context.styles.classes">
                        <div class="label-container"
                              title="{{fields.context.label}}{{fields.context.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.context.label}}">{{fields.context.label}}<span *ngIf="fields.context.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Notifications-auto_email_rule_contexts_dd_single 
                            data-cy="selector"
                            [type]="fields.context.control.type"
                            formControlName="context"
                            (displayTextChange)="fields.context.control.displayText=$event"
                            [placeholder]="fields.context.control.placeholder"
                            [styles]="fields.context.control.styles"
                        >
                        </Notifications-auto_email_rule_contexts_dd_single>
                        <ng-container *ngIf="fields.context.invalid">
                          <ng-container *ngFor="let error of fields.context.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-include_attachments" *ngIf="!fields.include_attachments.hidden" 
                            class="field-container double {{fields.include_attachments.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.include_attachments.styles.style"
                            [ngClass]="fields.include_attachments.styles.classes">
                        <div class="label-container"
                              title="{{fields.include_attachments.label}}{{fields.include_attachments.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.include_attachments.label}}">{{fields.include_attachments.label}}<span *ngIf="fields.include_attachments.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="include_attachments"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.include_attachments.control.styles.style"
                                      [ngClass]="fields.include_attachments.control.styles.classes">{{fields.include_attachments.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.include_attachments.invalid">
                          <ng-container *ngFor="let error of fields.include_attachments.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-set_schedule" *ngIf="!fields.set_schedule.hidden" 
                            class="field-container standard {{fields.set_schedule.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.set_schedule.styles.style"
                            [ngClass]="fields.set_schedule.styles.classes">
                        <div class="label-container"
                              title="{{fields.set_schedule.label}}{{fields.set_schedule.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.set_schedule.label}}">{{fields.set_schedule.label}}<span *ngIf="fields.set_schedule.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.set_schedule.control.readOnly"
                                [ngStyle]="fields.set_schedule.control.styles.style"
                                [ngClass]="fields.set_schedule.control.styles.classes"
                                (click)="on_schedule_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.set_schedule.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.set_schedule.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.set_schedule.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.set_schedule.invalid">
                          <ng-container *ngFor="let error of fields.set_schedule.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dock_appointment_carrier" *ngIf="!fields.dock_appointment_carrier.hidden" 
                            class="field-container standard {{fields.dock_appointment_carrier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dock_appointment_carrier.styles.style"
                            [ngClass]="fields.dock_appointment_carrier.styles.classes">
                        <div class="label-container"
                              title="{{fields.dock_appointment_carrier.label}}{{fields.dock_appointment_carrier.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dock_appointment_carrier.label}}">{{fields.dock_appointment_carrier.label}}<span *ngIf="fields.dock_appointment_carrier.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Notifications-carriers_dd_single 
                            data-cy="selector"
                            [type]="fields.dock_appointment_carrier.control.type"
                            formControlName="dock_appointment_carrier"
                            (displayTextChange)="fields.dock_appointment_carrier.control.displayText=$event"
                            [placeholder]="fields.dock_appointment_carrier.control.placeholder"
                            [styles]="fields.dock_appointment_carrier.control.styles"
                        >
                        </Notifications-carriers_dd_single>
                        <ng-container *ngIf="fields.dock_appointment_carrier.invalid">
                          <ng-container *ngFor="let error of fields.dock_appointment_carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dock_appointment_type" *ngIf="!fields.dock_appointment_type.hidden" 
                            class="field-container standard {{fields.dock_appointment_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dock_appointment_type.styles.style"
                            [ngClass]="fields.dock_appointment_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.dock_appointment_type.label}}{{fields.dock_appointment_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dock_appointment_type.label}}">{{fields.dock_appointment_type.label}}<span *ngIf="fields.dock_appointment_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Notifications-dock_appointment_types_dd_single 
                            data-cy="selector"
                            [type]="fields.dock_appointment_type.control.type"
                            formControlName="dock_appointment_type"
                            (displayTextChange)="fields.dock_appointment_type.control.displayText=$event"
                            [placeholder]="fields.dock_appointment_type.control.placeholder"
                            [styles]="fields.dock_appointment_type.control.styles"
                        >
                        </Notifications-dock_appointment_types_dd_single>
                        <ng-container *ngIf="fields.dock_appointment_type.invalid">
                          <ng-container *ngFor="let error of fields.dock_appointment_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-recipients"
                     *ngIf="!fieldsets.recipients.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.recipients.collapsible }">
                    <div *ngIf="!fieldsets.recipients.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.recipients.toggle()">
                      <span class="fieldsetsTitle-text">Recipients</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.recipients.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.recipients.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.recipients.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.recipients.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-to" *ngIf="!fields.to.hidden" 
                            class="field-container full {{fields.to.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.to.styles.style"
                            [ngClass]="fields.to.styles.classes">
                        <div class="label-container"
                              title="{{fields.to.label}}{{fields.to.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.to.label}}">{{fields.to.label}}<span *ngIf="fields.to.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="to"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.to.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.to.control.placeholder}}"
                                [ngStyle]="fields.to.control.styles.style"
                                [ngClass]="fields.to.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.to.invalid">
                          <ng-container *ngFor="let error of fields.to.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-use_cc" *ngIf="!fields.use_cc.hidden" 
                            class="field-container standard {{fields.use_cc.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_cc.styles.style"
                            [ngClass]="fields.use_cc.styles.classes">
                        <div class="label-container"
                              title="{{fields.use_cc.label}}{{fields.use_cc.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.use_cc.label}}">{{fields.use_cc.label}}<span *ngIf="fields.use_cc.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.use_cc.control.readOnly"
                                [ngStyle]="fields.use_cc.control.styles.style"
                                [ngClass]="fields.use_cc.control.styles.classes"
                                (click)="on_use_cc_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.use_cc.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.use_cc.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.use_cc.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.use_cc.invalid">
                          <ng-container *ngFor="let error of fields.use_cc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-cc" *ngIf="!fields.cc.hidden" 
                            class="field-container standard {{fields.cc.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.cc.styles.style"
                            [ngClass]="fields.cc.styles.classes">
                        <div class="label-container"
                              title="{{fields.cc.label}}{{fields.cc.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.cc.label}}">{{fields.cc.label}}<span *ngIf="fields.cc.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="cc"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.cc.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.cc.control.placeholder}}"
                                [ngStyle]="fields.cc.control.styles.style"
                                [ngClass]="fields.cc.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.cc.invalid">
                          <ng-container *ngFor="let error of fields.cc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-use_bcc" *ngIf="!fields.use_bcc.hidden" 
                            class="field-container standard {{fields.use_bcc.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_bcc.styles.style"
                            [ngClass]="fields.use_bcc.styles.classes">
                        <div class="label-container"
                              title="{{fields.use_bcc.label}}{{fields.use_bcc.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.use_bcc.label}}">{{fields.use_bcc.label}}<span *ngIf="fields.use_bcc.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.use_bcc.control.readOnly"
                                [ngStyle]="fields.use_bcc.control.styles.style"
                                [ngClass]="fields.use_bcc.control.styles.classes"
                                (click)="on_use_bcc_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.use_bcc.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.use_bcc.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.use_bcc.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.use_bcc.invalid">
                          <ng-container *ngFor="let error of fields.use_bcc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-bcc" *ngIf="!fields.bcc.hidden" 
                            class="field-container standard {{fields.bcc.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.bcc.styles.style"
                            [ngClass]="fields.bcc.styles.classes">
                        <div class="label-container"
                              title="{{fields.bcc.label}}{{fields.bcc.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.bcc.label}}">{{fields.bcc.label}}<span *ngIf="fields.bcc.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="bcc"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.bcc.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.bcc.control.placeholder}}"
                                [ngStyle]="fields.bcc.control.styles.style"
                                [ngClass]="fields.bcc.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.bcc.invalid">
                          <ng-container *ngFor="let error of fields.bcc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-content"
                     *ngIf="!fieldsets.content.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.content.collapsible }">
                    <div *ngIf="!fieldsets.content.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.content.toggle()">
                      <span class="fieldsetsTitle-text">Content</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.content.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.content.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.content.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.content.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-add_parameter" *ngIf="!fields.add_parameter.hidden" 
                            class="field-container standard {{fields.add_parameter.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.add_parameter.styles.style"
                            [ngClass]="fields.add_parameter.styles.classes">
                        <div class="label-container"
                              title="{{fields.add_parameter.label}}{{fields.add_parameter.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.add_parameter.label}}">{{fields.add_parameter.label}}<span *ngIf="fields.add_parameter.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.add_parameter.control.readOnly"
                                [ngStyle]="fields.add_parameter.control.styles.style"
                                [ngClass]="fields.add_parameter.control.styles.classes"
                                (click)="on_add_parameter_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.add_parameter.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.add_parameter.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.add_parameter.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.add_parameter.invalid">
                          <ng-container *ngFor="let error of fields.add_parameter.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-subject" *ngIf="!fields.subject.hidden" 
                            class="field-container full {{fields.subject.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.subject.styles.style"
                            [ngClass]="fields.subject.styles.classes">
                        <div class="label-container"
                              title="{{fields.subject.label}}{{fields.subject.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.subject.label}}">{{fields.subject.label}}<span *ngIf="fields.subject.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="subject"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.subject.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.subject.control.placeholder}}"
                                [ngStyle]="fields.subject.control.styles.style"
                                [ngClass]="fields.subject.control.styles.classes"> 
                        <ng-container *ngIf="fields.subject.invalid">
                          <ng-container *ngFor="let error of fields.subject.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-body" *ngIf="!fields.body.hidden" 
                            class="field-container full {{fields.body.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.body.styles.style"
                            [ngClass]="fields.body.styles.classes">
                        <div class="label-container"
                              title="{{fields.body.label}}{{fields.body.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.body.label}}">{{fields.body.label}}<span *ngIf="fields.body.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="body"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.body.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.body.control.placeholder}}"
                                [ngStyle]="fields.body.control.styles.style"
                                [ngClass]="fields.body.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.body.invalid">
                          <ng-container *ngFor="let error of fields.body.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.reports.hidden" class="tab" data-cy="tab-reports">
              <h2 [className]="tabs.reports.active? 'active': ''" (click)="tabs.reports.activate()">{{tabs.reports.title}}</h2>
            </div>
          </div>
        
              <Notifications-auto_email_rule_reports_grid *ngIf="tabs.reports.active"
              #$tabs_reports
              [ruleId]="$tabs_reports_auto_email_rule_reports_grid_inParams_ruleId"
              [ruleContext]="$tabs_reports_auto_email_rule_reports_grid_inParams_ruleContext"
              [readOnly]="$tabs_reports_auto_email_rule_reports_grid_inParams_readOnly"
              (outParamsChange)="on_reports_changed($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_reports')">
              </Notifications-auto_email_rule_reports_grid>
        </div>
      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>