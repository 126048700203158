import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService, EModalSize, EToasterType, EToasterPosition } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 



interface IInvoices_invoicing_rules_gridComponentEntity {
instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean, priority?: number, isTemplateRule?: boolean, priorityId?: string}

interface IInvoices_invoicing_rules_gridComponentInParams {
  projectId?: number}


class Invoices_invoicing_rules_gridComponentRowModel extends GridRowModel {
  grid: Invoices_invoicing_rules_gridComponent;
  entity: IInvoices_invoicing_rules_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    active_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    use_existing_invoice_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    priority: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    raise_priority: new GridCellModel(
      new CellStyles(null, null),
      new ButtonModel('', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_arrow_up_20_regular')
,
null
      ),
    lower_priority: new GridCellModel(
      new CellStyles(null, null),
      new ButtonModel('', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_arrow_down_20_regular')
,
null
      ),
    id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    name: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    active: new GridCellModel(
      new CellStyles(['center'], null),
      new CheckBoxModel(this.formGroup.controls['active_display'] as DatexFormControl, null, true, '')
,
null
      ),
    projectId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    term: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    tax_schedule: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    group_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    use_existing_invoice: new GridCellModel(
      new CellStyles(['center'], null),
      new CheckBoxModel(this.formGroup.controls['use_existing_invoice_display'] as DatexFormControl, null, true, '')
,
null
      ),
    billing_aggregation_strategies: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    createdOn: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    createdBy: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modifiedOn: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modifiedBy: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Invoices_invoicing_rules_gridComponent, entity: IInvoices_invoicing_rules_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.instructionId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Invoices_invoicing_rules_gridComponent, entity?: IInvoices_invoicing_rules_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.instructionId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.instructionId;
    const inParams = {
      $keys:[$resultKey],
      search:  null ,
      billingContractId:  null ,
      projectId:  $grid.inParams.projectId ,
      active:  null ,
      excludeRuleIds:  null ,
    };
    const data = await this.datasources.Invoices.ds_invoicing_instructions.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.priority.displayControl as TextModel).text = $row.entity.priority?.toString();
    (this.cells.id.displayControl as TextModel).text = $row.entity.instructionId;
    (this.cells.name.displayControl as TextModel).text = $row.entity.instructionName;
    (this.cells.active.displayControl as CheckBoxModel).reset($row.entity.active);
    (this.cells.projectId.displayControl as TextModel).text = $row.entity.projectId?.toString();
    (this.cells.type.displayControl as TextModel).text = $row.entity.instructionType;
    (this.cells.group_by.displayControl as TextModel).text = $row.entity.oneInvoicePerShipment ? 'Shipment' : $row.entity.oneInvoicePerWarehouse ? 'Warehouse' : 'Rule';
    (this.cells.use_existing_invoice.displayControl as CheckBoxModel).reset($row.entity.useExistingInvoice);
    (this.cells.createdOn.displayControl as TextModel).text = $row.entity.createdOn?.toString();
    (this.cells.createdBy.displayControl as TextModel).text = $row.entity.createdBy;
    (this.cells.modifiedOn.displayControl as TextModel).text = $row.entity.modifiedOn?.toString();
    (this.cells.modifiedBy.displayControl as TextModel).text = $row.entity.modifiedBy;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'name' : {
        this.on_name_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_name_clicked(event = null) {
    return this.on_name_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_name_clickedInternal(
    $row: Invoices_invoicing_rules_gridComponentRowModel,
  $grid: Invoices_invoicing_rules_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($grid.inParams.projectId) && $grid.inParams.projectId > 0 && $row.entity.projectId === 0) { return; }
  
  await $shell.Invoices.openauto_invoicing_rule_editorDialog({
      ruleId: $row.entity.instructionId,
      projectId: $grid.inParams.projectId
  }, 'flyout', EModalSize.Large);
  
  $grid.refresh();
  }
  change_priority(event = null) {
    return this.change_priorityInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async change_priorityInternal(
    $row: Invoices_invoicing_rules_gridComponentRowModel,
  $grid: Invoices_invoicing_rules_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const direction = $event?.trim()?.toUpperCase();
  
  if (!$utils.isDefinedTrimmed(direction) || !['UP', 'DOWN'].includes(direction.trim().toUpperCase())) { return; }
  
  const INDEX_CHANGE = direction === 'UP' ? -1 : 1;
  
  const allRecords = (await $datasources.Invoices.ds_invoicing_instructions.getList({ projectId: $grid.inParams.projectId })).result;
  const currentRecordIndex = allRecords.findIndex(r => r.instructionId === $row.entity.instructionId);
  const swapRecord = allRecords[currentRecordIndex + INDEX_CHANGE];
  
  if (!$utils.isDefined(swapRecord)) { return; }
  
  let currentPriority:number = $row.entity.priority;
  let swapPriority:number = swapRecord.priority;
  
  lock_priorities();
  
  // Clean priorities
  if ([currentPriority, swapPriority].includes(0)) {
      if (direction === 'UP') {
          currentPriority = 2;
          swapPriority = 1;
      } else {
          currentPriority = 1;
          swapPriority = 2;
      }
  }
  
  if (currentPriority === swapPriority) {
      if (direction === 'UP') {
          currentPriority++;
      } else {
          swapPriority++;
      }
  }
  let cascadePriorities: Set<number> = new Set([currentPriority, swapPriority].sort((a, b) => a - b));
  while ($utils.isDefined(cascadePriorities)) {
      const checkPriority: number = cascadePriorities.values().next().value;
  
      for (let record of allRecords) {
          if (record.priority === checkPriority && ![swapRecord.instructionId, $row.entity.instructionId].includes(record.instructionId)) {
              const targetPriority = checkPriority + 1;
  
              setPriority(record.instructionId, targetPriority);
  
              if (!cascadePriorities.has(targetPriority)) {
                  cascadePriorities.add(targetPriority);
              }
          }
      }
  
      cascadePriorities.delete(checkPriority);
  }
  
  
  await swapPriorities({ id: $row.entity.instructionId, priority: currentPriority }, { id: swapRecord.instructionId, priority: swapPriority })
  
  
  $grid.refresh();
  
  
  
  
  /******************************************************
   * FUNCTIONS
  *******************************************************/
  function lock_priorities() {
      for (let row of $grid.rows) {
          row.cells.raise_priority.displayControl.readOnly = true;
          row.cells.lower_priority.displayControl.readOnly = true;
      }
  }
  
  function unlockPriorities() {
      for (let row of $grid.rows) {
          row.cells.raise_priority.displayControl.readOnly = false;
          row.cells.lower_priority.displayControl.readOnly = false;
      }
  }
  
  async function swapPriorities(firstInstruction: { id: string; priority: number; }, secondInstruction: { id: string; priority: number; }) {
      await setPriority(firstInstruction.id, secondInstruction.priority);
      await setPriority(secondInstruction.id, firstInstruction.priority);
  }
  
  async function setPriority(instructionId: string, priority: number) {
      const targetRecord = allRecords.find(r => r.instructionId === instructionId);
  
      if ($utils.isDefined(targetRecord.priorityId)) {
          await $flows.Invoices.update_invoicing_project_rule_priority({
              priority_id: targetRecord.priorityId,
              priority: priority
          });
      } else {
          await $flows.Invoices.insert_invoicing_project_rule_priority({
              rule_id: targetRecord.instructionId,
              project_id: $grid.inParams.projectId,
              priority: priority
          })
      }
  }
  
  function cleanPriorities(currentPriority: number, swapPriority: number) {
  
  }
  }
  on_raise_priority_clicked(event = null) {
    return this.on_raise_priority_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_raise_priority_clickedInternal(
    $row: Invoices_invoicing_rules_gridComponentRowModel,
  $grid: Invoices_invoicing_rules_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  await $row.change_priority('UP');
  }
  on_lower_priority_clicked(event = null) {
    return this.on_lower_priority_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lower_priority_clickedInternal(
    $row: Invoices_invoicing_rules_gridComponentRowModel,
  $grid: Invoices_invoicing_rules_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  await $row.change_priority('DOWN');
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Invoices-invoicing_rules_grid',
  templateUrl: './Invoices.invoicing_rules_grid.component.html'
})
export class Invoices_invoicing_rules_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_invoicing_rules_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['set-width-table']);

  fullTextSearch: string;

  inParams: IInvoices_invoicing_rules_gridComponentInParams = { projectId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     priority: new GridHeaderModel(new HeaderStyles(null, null), 'Priority', false, false, 70),       raise_priority: new GridHeaderModel(new HeaderStyles(null, null), ' ', false, false, 40),       lower_priority: new GridHeaderModel(new HeaderStyles(null, null), ' ', false, false, 40),       id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, 200),       name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, 200),       active: new GridHeaderModel(new HeaderStyles(['center'], null), 'Active', false, false, 80),       projectId: new GridHeaderModel(new HeaderStyles(null, null), 'Project ID', false, false, 100),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', false, false, 120),       term: new GridHeaderModel(new HeaderStyles(null, null), 'Invoice term', false, false, 120),       tax_schedule: new GridHeaderModel(new HeaderStyles(null, null), 'Tax schedule', false, false, 150),       group_by: new GridHeaderModel(new HeaderStyles(null, null), 'Group billing records by', false, false, 150),       use_existing_invoice: new GridHeaderModel(new HeaderStyles(['center'], null), 'Add billing records to existing invoices', false, false, 250),       billing_aggregation_strategies: new GridHeaderModel(new HeaderStyles(null, null), 'Billing aggregation strategies', false, false, 400),       createdOn: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, 200),       createdBy: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, 200),       modifiedOn: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, 200),       modifiedBy: new GridHeaderModel(new HeaderStyles(null, null), 'Modified by', false, false, 200),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_invoicing_rules_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }

  topToolbar = {
      new_rule: new ToolModel(new ButtonModel('new_rule', new ButtonStyles(null, null), false, 'New rule', 'icon-ic_fluent_add_20_regular')
    ),
      add_rule_from_template: new ToolModel(new ButtonModel('add_rule_from_template', new ButtonStyles(null, null), false, 'Add from template', 'icon-ic_fluent_document_one_page_add_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_rules: new ToolModel(new ButtonModel('delete_rules', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      field_selector: new ToolModel(new SplitButtonModel<{ fs_priority: ButtonModel, fs_id: ButtonModel, fs_name: ButtonModel, fs_active: ButtonModel, fs_project_id: ButtonModel, fs_type: ButtonModel, fs_invoice_term: ButtonModel, fs_tax_schedule: ButtonModel, fs_invoice_per_shipment: ButtonModel, fs_invoice_per_warehouse: ButtonModel, fs_use_existing_invoice: ButtonModel, fs_billing_aggregation_strategies: ButtonModel, fs_created_on: ButtonModel, fs_created_by: ButtonModel, fs_modified_on: ButtonModel, fs_modified_by: ButtonModel }>(
        'field_selector',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_multiselect_ltr_20_filled',
        [
          new ButtonModel('fs_priority', new ButtonStyles(['creation'], null), false, 'Priority', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_id', new ButtonStyles(null, null), false, 'ID', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_name', new ButtonStyles(['creation'], null), true, 'Name', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_active', new ButtonStyles(['creation'], null), true, 'Active', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_project_id', new ButtonStyles(['creation'], null), true, 'Project ID', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_type', new ButtonStyles(['creation'], null), true, 'Type', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_invoice_term', new ButtonStyles(['creation'], null), true, 'Invoice term', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_tax_schedule', new ButtonStyles(['creation'], null), true, 'Tax schedule', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_invoice_per_shipment', new ButtonStyles(['creation'], null), true, 'Invoice per shipment', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_invoice_per_warehouse', new ButtonStyles(['creation'], null), true, 'Invoice per warehouse', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_use_existing_invoice', new ButtonStyles(['creation'], null), true, 'Use existing invoice', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_billing_aggregation_strategies', new ButtonStyles(['creation'], null), true, 'Billing aggregation strategies', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_created_on', new ButtonStyles(null, null), false, 'Created on', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_created_by', new ButtonStyles(null, null), false, 'Created by', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_modified_on', new ButtonStyles(null, null), false, 'Modified on', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_modified_by', new ButtonStyles(null, null), false, 'Modified by', 'icon-ic_fluent_circle_20_regular')
        ])
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Auto-invoicing rules';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      search:  null ,
      billingContractId:  null ,
      projectId:  $grid.inParams.projectId ,
      active:  null ,
      excludeRuleIds:  null ,
    };
    try {
    const data = await this.datasources.Invoices.ds_invoicing_instructions.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_invoicing_rules_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Invoices_invoicing_rules_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  setPriorityButtons();
  formatDates();
  
  if ($grid.inParams.projectId ?? 0 > 0) {
      for (let row of $grid.rows) {
          if (row.entity.projectId === 0) {
              set_style_to_template(row);
          }
      }
  }
  
  for (let toolbarItem in $grid.topToolbar) {
      $grid.topToolbar[toolbarItem].control.readOnly = !$utils.isDefined($grid.inParams.projectId);
  }
  
  const p_terms = $datasources.Invoices.ds_invoice_terms_dd.get({});
  const p_taxes = $datasources.Invoices.ds_tax_schedules_dd.get({});
  const p_billing_aggregation = $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({});
  
  const [{ result: terms }, { result: taxes }, { result: billing_aggregation }] = await Promise.all([p_terms, p_taxes, p_billing_aggregation]);
  
  for (let row of $grid.rows) {
      let bs_ids = row.entity.billingAggregationStrategyIds ?? [];
  
      if (bs_ids.length > 0) {
  
          let bs_names = billing_aggregation.filter(bs => bs_ids.indexOf(bs.Id) >= 0)?.map(bs => bs.EnumName).join(', ') ?? "";
  
          if (bs_names.length > 50) {
              bs_names = bs_names.slice(0, 47)?.trim()
              bs_names = `${bs_names}...`
          }
          row.cells.billing_aggregation_strategies.displayControl.text = bs_names
      }
  
      row.cells.term.displayControl.text = terms.find(t => t.Id === row.entity.termId)?.Name;
      row.cells.tax_schedule.displayControl.text = taxes.find(t => t.Id === row.entity.taxScheduleId)?.Label;
  }
  
  
  
  /**************************************
   * FUNCTIONS
  ***************************************/
  function set_style_to_template(row: typeof $grid.rows[0]) {
      row.cells.name.styles.clearClasses();
      row.cells.name.displayControl.text = `${row.entity.instructionName} (from template)`;
  
      for (let headerKey in $grid.headers) {
          if (['raise_priority', 'lower_priority', 'priority'].includes(headerKey)) { continue; }
          row.cells[headerKey].styles.setPlannedClass();
          row.cells[headerKey].displayControl.readOnly = true;
          if ($utils.isDefined(row.cells[headerKey].editControl?.readOnly)) {
              row.cells[headerKey].editControl.readOnly = true;
          }
      }
  }
  
  function formatDates() {
      for (let row of $grid.rows) {
          row.cells.createdOn.displayControl.text = $utils.date.format(row.cells.createdOn.displayControl.text, 'L LT');
          row.cells.modifiedOn.displayControl.text = $utils.date.format(row.cells.modifiedOn.displayControl.text, 'L LT');
      }
  }
  
  function setPriorityButtons() {
      $grid.rows.forEach((row, index) => {
          const isFirst = index === 0;
          const isLast = index === $grid.rows.length - 1;
  
          if (isFirst) {
              // Hide 'raise_priority' button
              row.cells.raise_priority.displayControl.readOnly = true;
              row.cells.raise_priority.displayControl.icon = null;
          }
          if (isLast) {
              // Hide 'lower_priority' button
              row.cells.lower_priority.displayControl.readOnly = true;
              row.cells.lower_priority.displayControl.icon = null;
          }
          if (!isFirst && !isLast) {
              // Unhide priority buttons
              row.cells.raise_priority.displayControl.readOnly = false;
              row.cells.raise_priority.displayControl.icon = 'icon-ic_fluent_arrow_up_20_regular';
  
              row.cells.lower_priority.displayControl.readOnly = false;
              row.cells.lower_priority.displayControl.icon = 'icon-ic_fluent_arrow_down_20_regular';
          }
      });
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Invoices_invoicing_rules_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const selected_rows = $grid.selectedRows;
  let errors: { name: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  const pluralChar = selected_rows.length > 1 ? 's' : '';
  
  if (selected_rows.length === 0) {
      $shell.Invoices.openToaster('Error deleting rules', 'No rules were selected', EToasterType.Error, { positionClass: EToasterPosition.topRight, timeOut: 5000});
      return;
  }
  const confirm_purge = (await $shell.Invoices.openConfirmationDialog(`Delete ${selected_rows.length} invoice rule${pluralChar}?`, null, `Delete rule${pluralChar}`, `Cancel`));
  
  if (confirm_purge) {
      const isIncludeTemplateRules = ["TRUE", "1"].includes((await $flows.Invoices.invoicing_option({
          action: "Read",
          payload: {
              dimension: "ProjectsInheritTemplateRules"
          }
      })).payload[0]?.value?.trim()?.toUpperCase());
  
      for (const row of selected_rows) {
  
          let current_error: string;
          let success = false;
          current_error = null;
  
          try {
  
              if (row.entity.projectId === 0) {
                  await delete_template_rule(row, isIncludeTemplateRules);
              }
              else {
                  await delete_rule(row);
              }
  
              if ($utils.isDefined(row.entity.priorityId)) {
                  await $flows.Invoices.delete_invoicing_project_rule_priority({ id: row.entity.priorityId });
              }
  
              success = true;
  
          } catch (error) {
  
              let target_error = error;
  
              if (typeof target_error === "string") {
                  target_error = { message: target_error };
              } else {
                  while ($utils.isDefined(target_error.error)) {
                      target_error = target_error.error;
                  };
              };
  
              if (!$utils.isDefined(target_error.message)) {
                  target_error = { message: `Uncaught exception: ${JSON.stringify(target_error)}` };
              };
  
              current_error = target_error.message;
          };
  
          if (success) {
              deleted_records.push(row.entity.instructionId.toString())
          } else {
              errors.push({
                  name: row.entity.instructionName, id: row.entity.instructionId, errorMessages: [current_error]
              });
          };
      };
  
      if ($utils.isDefined(errors)) {
          await $shell.Invoices.openErrorDialog(
              `Delete record${selected_rows.length > 1 ? 's' : ''}`,
              deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deleted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
              null,
              `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
              errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} name: ${error.name}` })))
      };
  };
  
  
  displayResult();
  $grid.refresh();
  
  
  
  
  
  
  /********************************************
   * FUNCTIONS
  *********************************************/
  const log_schema = (await $flows.Invoices.invoicing_log({ action: "" })).payload;
  async function log_add(input: typeof log_schema[0]) {
      await $flows.Invoices.invoicing_log({ action: "Write", payload: input });
  };
  
  const instruction_schema = (await $flows.Invoices.invoicing_instruction({ action: "" })).payload;
  async function instruction_delete(input: typeof instruction_schema[0]) {
      await $flows.Invoices.invoicing_instruction({ action: "Delete", payload: input });
  };
  
  
  async function delete_template_rule(row: typeof $grid.rows[0], isIncludeTemplateRules: boolean) {
      if (($grid.inParams.projectId ?? 0) === 0) {
          // Delete the rule
          await delete_rule(row);
      }
      else {
          if (isIncludeTemplateRules) {
              // Add exclusion exception
              await $flows.Invoices.insert_invoicing_rule_template_exception({
                  rule_id: row.entity.instructionId,
                  project_id: $grid.inParams.projectId,
                  exception: 'exclude'
              });
          }
          else {
              // Remove inclusion exception
              await $flows.Invoices.delete_invoicing_rule_template_exception({
                  rule_id: row.entity.instructionId,
                  project_id: $grid.inParams.projectId,
                  exception: 'include'
              });
          }
      }
  }
  
  async function delete_rule(row: typeof $grid.rows[0]) {
      let entity = row.entity;
  
      await instruction_delete({
          "instructionId": entity.instructionId
      });
  
      await log_add({
          "process": "invoicing_instructions_grid",
          "level": 1,
          "billingContractId": entity.billingContractId,
          "projectId": entity.projectId,
          "notes": `Deleted record! name: [${entity.instructionName}] Instruction Id: [${entity.instructionId}]`
      });
  }
  
  function displayResult() {
      const hasSuccess = deleted_records.length > 0;
      const hasFailure = errors.length > 0;
  
      const pluralChar = $grid.selectedRows.length > 1 ? 's' : '';
  
      let title: string;
      let message: string;
      let type: EToasterType;
  
      if (hasSuccess && hasFailure) {
          // Show warning
          title = `${deleted_records.length} of ${$grid.selectedRows.length} rule${pluralChar} deleted`;
          type = EToasterType.Warning;
      }
      else if (hasSuccess) {
          title = `${deleted_records.length} rule${pluralChar} deleted`;
          type = EToasterType.Success;
      }
      else if (hasFailure) {
          title = `Error deleting ${errors.length} rule${pluralChar}`;
          type = EToasterType.Error;
      }
  
      $shell.Invoices.openToaster(title, message, type, { positionClass: EToasterPosition.topRight, timeOut: 5000});
  }
  }
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $grid: Invoices_invoicing_rules_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  let allowDuplicates: boolean = false;
  let option = (await $flows.Invoices.invoicing_option({ action: "Read", payload: { dimension: "AllowDuplicateStrategies" } })).payload;
  if (option.length > 0) {
      let truth: any[] = ["TRUE", "1"]
      if (truth.includes(option[0].value.toUpperCase())) {
          allowDuplicates = true
      }
  } else {
      await $flows.Invoices.invoicing_option({ action: "Write", payload: { dimension: "AllowDuplicateStrategies", value: `${allowDuplicates}` } })
  }
  
  const projectId = $grid.inParams.projectId ?? 0;
  
  let can_create_instruction: boolean = false;
  
  if ($utils.isAllDefined(projectId)) {
  
      if (allowDuplicates) {
          can_create_instruction = true;
      } else {
  
          let instructions = (await $flows.Invoices.invoicing_instruction({
              action: "Read",
              payload: {
                  projectId: projectId
              }
          })).payload;
  
          if (instructions.length > 0) {
  
              const bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({})).result;
  
              const bs_already_used = instructions.filter(i => $utils.isDefined(i.billingAggregationStrategyIds)).map(i => i.billingAggregationStrategyIds).flat();
  
              let bs_available = bs.filter(bs => bs_already_used.indexOf(bs.Id) === -1)?.map(bs => bs.Id)
  
              if (bs_available.length === 0) {
  
                  await $shell.Invoices.openInfoDialog("All assigned!", "All your billing aggregation strategies have been assigned to the instructions on your contract, you may not add another invoicing instruction!")
                  can_create_instruction = false;
  
              } else {
                  can_create_instruction = true;
              }
          } else {
              can_create_instruction = true;
          }
      }
  
      if (can_create_instruction) {
  
          await $shell.Invoices.openauto_invoicing_rule_editorDialog({
              ruleId: "",
              projectId: $grid.inParams.projectId
          }, 'flyout', EModalSize.Large);
      }
      $grid.refresh();
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Invoices_invoicing_rules_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.delete_rules.control.readOnly = true;
  
  $grid.headers.id.hidden = true;
  $grid.headers.createdOn.hidden = true;
  $grid.headers.createdBy.hidden = true;
  $grid.headers.modifiedOn.hidden = true;
  $grid.headers.modifiedBy.hidden = true;
  
  if ($utils.isDefined($grid.inParams.projectId)) {
      $grid.headers.projectId.hidden = true;
  }
  
  if ($grid.inParams.projectId === 0) {
      $grid.topToolbar.add_rule_from_template.hidden = true;
      $grid.title = `Template rules`;
  } else {
      $grid.topToolbar.add_rule_from_template.hidden = false;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Invoices_invoicing_rules_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_rules.control.readOnly = selectedRowsCount === 0;
  }
  on_add_rule_from_template_clicked(event = null) {
    return this.on_add_rule_from_template_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_rule_from_template_clickedInternal(
    $grid: Invoices_invoicing_rules_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const rule_ids = (await $shell.Invoices.openinvoicing_template_rule_selection_gridDialog({ exclude_rule_ids: $grid.rows.filter(row => row.entity.projectId === 0).map(row => row.entity.instructionId) }, 'modal', EModalSize.Xlarge)).rule_ids;
  
  if (!$utils.isDefined(rule_ids)) { return; }
  
  const isIncludeTemplateRules = ["TRUE", "1"].includes((await $flows.Invoices.invoicing_option({
      action: "Read",
      payload: {
          dimension: "ProjectsInheritTemplateRules"
      }
  })).payload[0]?.value?.trim()?.toUpperCase());
  
  for (let rule_id of rule_ids) {
      await add_template_rule(rule_id, isIncludeTemplateRules);
  }
  
  $grid.refresh();
  
  
  async function add_template_rule(rule_id: string, isIncludeTemplateRules: boolean) {
      if (isIncludeTemplateRules) {
          // Delete exclusion exception
          await $flows.Invoices.delete_invoicing_rule_template_exception({
              rule_id: rule_id,
              project_id: $grid.inParams.projectId,
              exception: 'exclude'
          });
      }
      else {
          // Add inclusion exception
          await $flows.Invoices.insert_invoicing_rule_template_exception({
              rule_id: rule_id,
              project_id: $grid.inParams.projectId,
              exception: 'include'
          });
      }
  }
  }
  on_field_select_changed(event = null) {
    return this.on_field_select_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_field_select_changedInternal(
    $grid: Invoices_invoicing_rules_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  let field_name = $event?.target?.innerText;
  let enabled = false;
  
  for (let key in $grid.headers) {
      if (field_name === $grid.headers[key].name) {
          if ($grid.headers[key].hidden) {
              $grid.headers[key].hidden = false;
              enabled = true;
          }  else {
              $grid.headers[key].hidden = true;
              enabled = false;
          }
      }
  }
  
  for (let key in $grid.topToolbar.field_selector.control.buttons) {
      if ($grid.topToolbar.field_selector.control.buttons[key].label === field_name) {
          if (enabled) {
              $grid.topToolbar.field_selector.control.buttons[key].icon = 'icon icon-ic_fluent_checkmark_circle_20_regular';
              $grid.topToolbar.field_selector.control.buttons[key].styles.setCreationClass();
          } else {
              $grid.topToolbar.field_selector.control.buttons[key].styles.clearClasses();
              $grid.topToolbar.field_selector.control.buttons[key].icon = 'icon icon-ic_fluent_circle_20_regular';
          }
      }
  }
  
  $event.preventDefault();
  $event.stopPropagation();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
