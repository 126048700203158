import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService, EModalSize, EToasterType, EToasterPosition } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 



interface INotifications_auto_email_rules_gridComponentEntity {
id?: string, context?: string, filters?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, notes?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, last_executed_on?: string, frequency?: string, projectId?: number, is_enabled?: boolean, include_attachments?: boolean, dock_appointment_carrier_id?: number, dock_appointment_type_id?: number}

interface INotifications_auto_email_rules_gridComponentInParams {
  entityType?: string, entityKeys?: { name: string, value: any }, projectId?: number, context?: string}


class Notifications_auto_email_rules_gridComponentRowModel extends GridRowModel {
  grid: Notifications_auto_email_rules_gridComponent;
  entity: INotifications_auto_email_rules_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    is_enabled_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    is_enabled: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['is_enabled_display'] as DatexFormControl, null, true, '')
,
null
      ),
    rule_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    context: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    frequency: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    last_executed_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    to: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    cc: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    bcc: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    subject: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    body: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modified_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Notifications_auto_email_rules_gridComponent, entity: INotifications_auto_email_rules_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Notifications_auto_email_rules_gridComponent, entity?: INotifications_auto_email_rules_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      projectId:  $grid.inParams.projectId ,
      context:  $grid.inParams.context ,
    };
    const data = await this.datasources.Notifications.ds_auto_email_rules.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.is_enabled.displayControl as CheckBoxModel).reset($row.entity.is_enabled);
    (this.cells.rule_id.displayControl as TextModel).text = $row.entity.id;
    (this.cells.context.displayControl as TextModel).text = $row.entity.context;
    (this.cells.last_executed_on.displayControl as TextModel).text = $row.entity.last_executed_on;
    (this.cells.to.displayControl as TextModel).text = $row.entity.to;
    (this.cells.cc.displayControl as TextModel).text = $row.entity.cc;
    (this.cells.bcc.displayControl as TextModel).text = $row.entity.bcc;
    (this.cells.subject.displayControl as TextModel).text = $row.entity.subject;
    (this.cells.body.displayControl as TextModel).text = $row.entity.body;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.notes;
    (this.cells.created_on.displayControl as TextModel).text = ($row.entity.created_on ?? '').toString();
    (this.cells.modified_on.displayControl as TextModel).text = ($row.entity.modified_on ?? '').toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'auto_email_rules_grid.on_save_new_row');
  // try {
  //     await $row.validate_row();
      
  //     let frequencyString = $utils.isDefined($row.entity.frequency) ? JSON.parse($row.entity.frequency) : undefined;
  
  //     let result = await $flows.Notifications.create_auto_email_rule_flow({
  //         ruleProperties: {
  //             trigger: $row.cells.trigger.editControl.value,
  //             filters: $row.cells.filters.editControl.value,
  //             to: $row.cells.to.editControl.value,
  //             cc: $row.cells.cc.editControl.value,
  //             bcc: $row.cells.bcc.editControl.value,
  //             subject: $row.cells.subject.editControl.value,
  //             body: $row.cells.body.editControl.value,
  //             notes: $row.cells.notes.editControl.value,
  //             frequency: frequencyString
  //         }
  //     });
  
  //     $row.entity.id = result.ruleId;
  
  //     $row.refresh();
  // }
  // catch (error) {
  //     await $shell.Notifications.openErrorDialog('Error creating rule', error.message);
  //     throw new Error(); // to prevent display mode
  // }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'auto_email_rules_grid.on_save_existing_row');
  // try {
  //     await $row.validate_row();
  
  //     //let frequency = $utils.isDefined($row.entity.frequency) ? JSON.parse($row.entity.frequency) : undefined;
  
  //     await $flows.Notifications.update_auto_email_rule_flow({
  //         ruleId: $row.entity.id,
  //         ruleProperties: {
  //             trigger: $row.cells.trigger.editControl.value,
  //             filters: $row.cells.filters.editControl.value,
  //             to: $row.cells.to.editControl.value,
  //             cc: $row.cells.cc.editControl.value,
  //             bcc: $row.cells.bcc.editControl.value,
  //             subject: $row.cells.subject.editControl.value,
  //             body: $row.cells.body.editControl.value,
  //             notes: $row.cells.notes.editControl.value,
  //             frequency: $row.entity.frequency
  //         }
  //     });
  
  //     $row.refresh();
  
  // }
  // catch (error) {
  //     await $shell.Notifications.openErrorDialog('Error updating rule', error.message);
  //     throw new Error(); // to prevent display mode
  // }
  
  
  }
  delete_row(event = null) {
    return this.delete_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async delete_rowInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'auto_email_rules_grid.delete_row');
  try {
      await $flows.Notifications.delete_auto_email_rule_flow({ ruleId: $row.entity.id });
  
      $grid.refresh();
  }
  catch (error) {
      await $shell.Notifications.openErrorDialog('Error deleting rule', error.message);
  }
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $row.cells.created_on.displayControl.text = `${formatDate($row.cells.created_on.displayControl.text)} by ${$row.entity.created_by}`;
  $row.cells.modified_on.displayControl.text = `${formatDate($row.cells.modified_on.displayControl.text)} by ${$row.entity.modified_by}`;
  $row.cells.last_executed_on.displayControl.text = formatDate($row.cells.last_executed_on.displayControl.text);
  
  
  await $row.set_frequency({ context: 'Display' });
  await $row.set_frequency({ context: 'Edit' });
  
  /****************************************
   * FUNCTIONS
  *****************************************/
  function formatDate(date: string) {
      let formattedDate: string = '';
  
      if ($utils.isDefinedTrimmed(date)) {
          let dateFormat = 'MM/DD/YYYY hh:mm A';
  
          let myDate = new Date(date);
  
          formattedDate = $utils.date.format(myDate.toString(), dateFormat);
      }
  
      return formattedDate;
  }
  
  
  }
  validate_row(event = null) {
    return this.validate_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async validate_rowInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'auto_email_rules_grid.validate_row');
  // // Check required fields
  // let requiredFields = [
  //     'to',
  //     'trigger',
  //     'subject'
  // ];
  
  // let missingFields: string[] = [];
  
  // for (let key of requiredFields) {
  //     if (!$utils.isDefined($row.cells[key].editControl.value)) {
  //         missingFields.push($grid.headers[key].name);
  //     }
  // }
  
  // if ($utils.isDefined(missingFields)) {
  //     throw new Error(`Missing required field${missingFields.length > 1 ? 's' : ''}: ${missingFields.join(', ')}`);
  // }
  
  
  // /*** Check email formatting ***/
  // function checkInvalidEmails(fieldName: string, emailString: string) {
  //     if ($utils.isDefinedTrimmed(emailString)) {
  //         let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //         let invalidEmails: string[] = [];
  
  //         let emails = emailString.split(';');
  
  //         for (let email of emails) {
  //             if ($utils.isDefinedTrimmed(email) && !regex.test(email)) {
  //                 invalidEmails.push(email);
  //             }
  //         }
  
  //         if ($utils.isDefined(invalidEmails)) { throw new Error(`Invalid email${invalidEmails.length > 1 ? 's' : ''} in the '${fieldName}' field: ${invalidEmails.join(', ')}`); }
  //     }
  // }
  
  // checkInvalidEmails('To', $row.cells.to.editControl.value);
  // checkInvalidEmails('CC', $row.cells.to.editControl.value);
  // checkInvalidEmails('BCC', $row.cells.to.editControl.value);
  
  
  // // Check if any identical email addresses exist in the to/cc/bcc fields
  // function checkForRepeatedEmails(fieldName1: string, emailString1: string, fieldName2: string, emailString2: string) {
  //     if ($utils.isDefined(emailString1) && $utils.isDefined(emailString2)) {
  //         let list1 = emailString1.split(';').map(email => email.trim().toLowerCase());
  //         let list2 = emailString2.split(';').map(email => email.trim().toLowerCase());
  
  //         let duplicatedEmails: string[] = [];
  
  //         for (const email of list1) {
  //             if (list2.includes(email)) {
  //                 duplicatedEmails.push(email);
  //             }
  //         }
  
  //         if ($utils.isDefined(duplicatedEmails)) { throw new Error(`The '${fieldName1}' and '${fieldName2}' fields both contain the following email${duplicatedEmails.length > 1 ? 's' : ''}: ${duplicatedEmails.join(', ')}`); }
  //     }
  // }
  
  // function checkForDuplicateEmails(fieldName: string, emailString: string) {
  //     if ($utils.isDefinedTrimmed(emailString)) {
  //         let emails = emailString.split(';').map(email => email.trim().toLowerCase());
  
  //         let duplicateEmails: string[] = [];
  //         let uniqueValues = new Set<string>();
  
  //         for (const email of emails) {
  //             if (uniqueValues.has(email)) {
  //                 if (!duplicateEmails.includes(email)) {
  //                     duplicateEmails.push(email);
  //                 }
  //             }
  //             else {
  //                 uniqueValues.add(email);
  //             }
  //         }
  
  //         if ($utils.isDefined(duplicateEmails)) { throw new Error(`The '${fieldName}' field contains the following email${duplicateEmails.length > 1 ? 's' : ''} multiple times: ${duplicateEmails.join(', ')}`); }
  //     }
  // }
  
  // checkForRepeatedEmails('To', $row.cells.to.editControl.value, 'CC', $row.cells.cc.editControl.value);
  // checkForRepeatedEmails('To', $row.cells.to.editControl.value, 'BCC', $row.cells.bcc.editControl.value);
  // checkForRepeatedEmails('CC', $row.cells.cc.editControl.value, 'BCC', $row.cells.bcc.editControl.value);
  
  // // Check for purely repeated emails
  // checkForDuplicateEmails('To', $row.cells.to.editControl.value);
  // checkForDuplicateEmails('CC', $row.cells.cc.editControl.value);
  // checkForDuplicateEmails('BCC', $row.cells.bcc.editControl.value);
  }
  on_frequency_clicked(event = null) {
    return this.on_frequency_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_frequency_clickedInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'auto_email_rules_grid.on_frequency_clicked');
  // if ($row.cells.frequency.isEdit) {
  
  //     let frequency: any;
  //     if ($utils.isDefinedTrimmed($row.entity.frequency)) {
  //         frequency = JSON.parse($row.entity.frequency)
  //     }
  
  //     let result = (await $shell.Notifications.openschedule_frequency_formDialog({ frequency: frequency }, 'modal'));
  
  //     if ($utils.isDefined(result.frequency)) {
  //         $row.entity.frequency = JSON.stringify(result.frequency);
  //     }
  
  //     let notes =  $row.cells.notes.editControl.value;
  //     $row.cells.notes.editControl.value = 'REFRESH';
  //     $row.cells.notes.editControl.value = notes;
  
  //     await $row.set_frequency({context: 'Edit'});
  // }
  }
  set_frequency(event = null) {
    return this.set_frequencyInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_frequencyInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefinedTrimmed($row.entity.frequency)) {
      return;
  }
  
  let schedule: $types.Notifications.AutoEmailSchedule = JSON.parse($row.entity.frequency);
  
  
  if (!$utils.isDefined(schedule)) { return; }
  
  
  // Set row frequency
  $row.cells.frequency.displayControl.text = (await $flows.Notifications.auto_email_schedule_format_flow({
      schedule: schedule
  })).scheduleString;
  }
  on_enabled_changed(event = null) {
    return this.on_enabled_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_enabled_changedInternal(
    $row: Notifications_auto_email_rules_gridComponentRowModel,
  $grid: Notifications_auto_email_rules_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'auto_email_rules_grid.on_enabled_changed');
  await $flows.Notifications.update_auto_email_rule_flow({
      ruleId: $row.entity.id,
      ruleProperties: {
          is_enabled: $row.cells.is_enabled.displayControl.value
      }
  });
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Notifications-auto_email_rules_grid',
  templateUrl: './Notifications.auto_email_rules_grid.component.html'
})
export class Notifications_auto_email_rules_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: INotifications_auto_email_rules_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: INotifications_auto_email_rules_gridComponentInParams = { entityType: null, entityKeys: { name: null, value: null }, projectId: null, context: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     is_enabled: new GridHeaderModel(new HeaderStyles(null, null), 'Enabled', false, false, null),       rule_id: new GridHeaderModel(new HeaderStyles(null, null), 'Rule ID', false, false, null),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       context: new GridHeaderModel(new HeaderStyles(null, null), 'Context', false, false, null),       frequency: new GridHeaderModel(new HeaderStyles(null, null), 'Frequency', false, false, null),       last_executed_on: new GridHeaderModel(new HeaderStyles(null, null), 'Last executed on', false, false, null),       to: new GridHeaderModel(new HeaderStyles(null, null), 'To', false, false, null),       cc: new GridHeaderModel(new HeaderStyles(null, null), 'CC', false, false, null),       bcc: new GridHeaderModel(new HeaderStyles(null, null), 'BCC', false, false, null),       subject: new GridHeaderModel(new HeaderStyles(null, null), 'Subject', false, false, null),       body: new GridHeaderModel(new HeaderStyles(null, null), 'Body', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       modified_on: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Notifications_auto_email_rules_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('entityType') set $inParams_entityType(value: any) {
    this.inParams['entityType'] = value;
  }
  get $inParams_entityType(): any {
    return this.inParams['entityType'] ;
  }
  @Input('entityKeys') set $inParams_entityKeys(value: any) {
    this.inParams['entityKeys'] = value;
  }
  get $inParams_entityKeys(): any {
    return this.inParams['entityKeys'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('context') set $inParams_context(value: any) {
    this.inParams['context'] = value;
  }
  get $inParams_context(): any {
    return this.inParams['context'] ;
  }

  topToolbar = {
      create_rule: new ToolModel(new ButtonModel('create_rule', new ButtonStyles(null, null), false, 'New rule', 'icon-ic_fluent_add_20_regular')
    ),
      edit_rule: new ToolModel(new ButtonModel('edit_rule', new ButtonStyles(null, null), false, 'Edit', 'icon-ic_fluent_edit_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_rule: new ToolModel(new ButtonModel('delete_rule', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      execute: new ToolModel(new ButtonModel('execute', new ButtonStyles(null, null), false, 'Execute', 'icon-ic_fluent_play_20_regular')
    ),
      request_history: new ToolModel(new ButtonModel('request_history', new ButtonStyles(null, null), false, 'Request history', 'icon-ic_fluent_history_20_regular')
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      field_selector: new ToolModel(new SplitButtonModel<{ fs_enabled: ButtonModel, fs_rule_id: ButtonModel, fs_owner: ButtonModel, fs_project: ButtonModel, fs_context: ButtonModel, fs_frequency: ButtonModel, fs_last_executed_on: ButtonModel, fs_to: ButtonModel, fs_cc: ButtonModel, fs_bcc: ButtonModel, fs_subject: ButtonModel, fs_body: ButtonModel, fs_notes: ButtonModel, fs_created_on: ButtonModel, fs_modified_on: ButtonModel }>(
        'field_selector',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_multiselect_ltr_20_filled',
        [
          new ButtonModel('fs_enabled', new ButtonStyles(['creation'], null), true, 'Enabled', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_rule_id', new ButtonStyles(null, null), false, 'Rule ID', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_owner', new ButtonStyles(['creation'], null), true, 'Owner', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_project', new ButtonStyles(['creation'], null), true, 'Project', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_context', new ButtonStyles(['creation'], null), true, 'Context', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_frequency', new ButtonStyles(['creation'], null), true, 'Frequency', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_last_executed_on', new ButtonStyles(['creation'], null), true, 'Last executed on', 'icon-ic_fluent_checkmark_circle_20_regular'),
          new ButtonModel('fs_to', new ButtonStyles(null, null), false, 'To', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_cc', new ButtonStyles(null, null), false, 'CC', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_bcc', new ButtonStyles(null, null), false, 'BCC', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_subject', new ButtonStyles(null, null), false, 'Subject', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_body', new ButtonStyles(null, null), false, 'Body', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_notes', new ButtonStyles(null, null), false, 'Notes', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_created_on', new ButtonStyles(null, null), false, 'Created on', 'icon-ic_fluent_circle_20_regular'),
          new ButtonModel('fs_modified_on', new ButtonStyles(null, null), false, 'Modified on', 'icon-ic_fluent_circle_20_regular')
        ])
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Auto-email rules';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      projectId:  $grid.inParams.projectId ,
      context:  $grid.inParams.context ,
    };
    try {
    const data = await this.datasources.Notifications.ds_auto_email_rules.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Notifications_auto_email_rules_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_execute_clicked(event = null) {
    return this.on_execute_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_execute_clickedInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let promises: Promise<{
      ruleId?: string;
      reasons?: string[];
  }>[] = [];
  
  for (let row of $grid.selectedRows) {
      for (let key in row.cells) {
          row.cells[key].styles.setPlannedClass();
      }
  
      promises.push($flows.Notifications.auto_email_execute_rule_flow({ ruleId: row.entity.id }));
      row.selected = false;
  }
  
  let results = await Promise.all(promises);
  
  let errors = results.filter(result => $utils.isDefined(result.reasons)).map(result => result.reasons.join(', '));
  
  
  await $shell.Notifications.openErrorDialog('Finished executing rules', null, null, 'Results', results.map(r => ({ 
      message: r.ruleId, 
      detail: $utils.isDefined(r.reasons) ? r.reasons.join(', ') : 'Success' })));
  
  
  for (let row of $grid.rows) {
      for (let key in row.cells) {
          row.cells[key].styles.resetClasses();
      }
      row.refresh();
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.execute.control.readOnly = $grid.selectedRows.length === 0;
  $grid.topToolbar.delete_rule.control.readOnly = $grid.selectedRows.length === 0;
  $grid.topToolbar.edit_rule.control.readOnly = $grid.selectedRows.length !== 1;
  $grid.topToolbar.request_history.control.readOnly = $grid.selectedRows.length !== 1;
  }
  on_edit_rule_clicked(event = null) {
    return this.on_edit_rule_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_rule_clickedInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  
  
  
  
  $shell.Notifications.openauto_email_rule_editor({ rule_id: $grid.selectedRows[0]?.entity.id });
  }
  on_create_rule_clicked(event = null) {
    return this.on_create_rule_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_rule_clickedInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $shell.Notifications.openauto_email_rule_editor({rule_id: null, project_id: $grid.inParams.projectId});
  }
  on_delete_rule_clicked(event = null) {
    return this.on_delete_rule_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_rule_clickedInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  const selected_row_count = $grid.selectedRows.length;
  
  if (selected_row_count === 0) {
      return;
  }
  
  let plural_char = $grid.selectedRows.length > 1 ? 's' : '';
  let is_confirmed = await $shell.Notifications.openConfirmationDialog(
      `Delete auto-email rule${plural_char}?`, 
      `Are you sure you want to delete the ${$grid.selectedRows.length > 1 ? `${$grid.selectedRows.length} ` : ''}selected rule${plural_char}?`,
      `Delete rule${plural_char}`, 'Cancel');
  if (!is_confirmed) {
      return;
  }
  
  let promises: any[] = [];
  for (let row of $grid.selectedRows) {
      for (let key in row.cells) {
          row.cells[key].displayControl.styles.setStyle('font-style', 'italic');
          row.cells[key].displayControl.styles.setStyle('color', 'gray');
          row.cells[key].displayControl.styles.setStyle('font-weight', 'bold');
      }
      promises.push(deleteRule(row.entity.id));
      row.selected = false;
  }
  
  let results = await Promise.all(promises);
  
  $shell.Notifications.openToaster(
      'Success',
      `Successfully deleted ${selected_row_count} auto-email rule${selected_row_count > 1 ? 's' : ''}`,
      EToasterType.Success,
      {
          timeOut: 5000,
          positionClass: EToasterPosition.topRight
      }
  )
  
  $grid.refresh();
  
  
  async function deleteRule(ruleId: string) {
      let attachments = (await $datasources.Notifications.ds_auto_email_rule_reports.getList({ruleId: ruleId})).result;
  
      for (let attachment of attachments) {
          await $flows.Notifications.delete_auto_email_rule_attachment_flow({ attachmentId: attachment.id});
      }
  
      let result = await $flows.Notifications.delete_auto_email_rule_flow({ ruleId: ruleId });
  
      return result;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.execute.hidden = true;
  
  if ($utils.isDefined($grid.inParams.projectId)) {
      // Hide columns
      $grid.headers.owner.hidden = true;
      $grid.headers.project.hidden = true;
  
      // Get owner and project
      let projectPromise = ($datasources.Notifications.ds_projects_dd.getByKeys({ $keys: [$grid.inParams.projectId] }));
      let ownerPromise = ($datasources.Notifications.ds_owners_dd.getList({ $top: 1, projectId: $grid.inParams.projectId }));
  
      let [projectResponse, ownerResponse] = await Promise.all([projectPromise, ownerPromise]);
  
      const project = projectResponse.result[0];
      const owner = ownerResponse.result[0];
  
      // Set title
      $grid.title = `${formatOwnerProjectName(owner.Name, project.Name)} auto-email rules`;
  }
  
  $grid.headers.rule_id.hidden = true;
  $grid.headers.to.hidden = true;
  $grid.headers.cc.hidden = true;
  $grid.headers.bcc.hidden = true;
  $grid.headers.subject.hidden = true;
  $grid.headers.body.hidden = true;
  $grid.headers.notes.hidden = true;
  $grid.headers.created_on.hidden = true;
  $grid.headers.modified_on.hidden = true;
  
  /********************************************
   * FUNCTIONS
  *********************************/
  function formatOwnerProjectName(ownerName: string, projectName: string): string {
      let ownerProjectName: string;
  
      ownerProjectName = ownerName.trim().toUpperCase() === projectName.trim().toUpperCase() ? projectName : `${ownerName}, ${projectName}`;
  
      return ownerProjectName;
  }
  
  
  /*********************************************
   * EVENTS
  **********************************************/
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($grid.inParams.projectId)) {
      let projects = (await $datasources.Notifications.ds_get_projects.get({ projectIds: $grid.rows.filter(r => $utils.isDefined(r.entity.projectId)).map(r => r.entity.projectId) })).result;
  
      for (let row of $grid.rows) {
          let project = projects.find(p => p.Id === row.entity.projectId);
  
          if ($utils.isDefined(project)) {
              row.cells.project.displayControl.text = project.Name;
              row.cells.owner.displayControl.text = project.Owner.Name;
          }
      }
  }
  
  add_enabled_listener();
  
  async function add_enabled_listener() {
      await new Promise(result => setTimeout(result, 100));
      const elements = document.querySelectorAll('[data-cy="slideToggle"]');
  
      if ($utils.isDefined(elements)) {
          // Add event listener for is_enabled update
          elements.forEach((element) => {
              element.addEventListener('click', async (event: MouseEvent) => {
                  await new Promise(result => setTimeout(result, 100));
  
                  for (let row of $grid.rows) {
                      if (row.cells.is_enabled.displayControl.value != row.entity.is_enabled) {
                          await $flows.Notifications.update_auto_email_rule_flow({ ruleId: row.entity.id, ruleProperties: { is_enabled: row.cells.is_enabled.displayControl.value } })
  
                          await row.refresh();
                      }
                  }
              }, true);
          });
  
          for (let row of $grid.rows) {
              row.cells.is_enabled.displayControl.readOnly = false;
          }
      }
  }
  }
  on_request_history_clicked(event = null) {
    return this.on_request_history_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_request_history_clickedInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openemail_requests_gridDialog({
      ruleId: $grid.selectedRows[0].entity.id
  }, 'flyout', EModalSize.Xlarge);
  }
  on_field_select_change(event = null) {
    return this.on_field_select_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_field_select_changeInternal(
    $grid: Notifications_auto_email_rules_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let field_name = $event?.target?.innerText;
  let enabled = false;
  
  for (let key in $grid.headers) {
      if (field_name === $grid.headers[key].name) {
          if ($grid.headers[key].hidden) {
              $grid.headers[key].hidden = false;
              enabled = true;
          }  else {
              $grid.headers[key].hidden = true;
              enabled = false;
          }
      }
  }
  
  for (let key in $grid.topToolbar.field_selector.control.buttons) {
      if ($grid.topToolbar.field_selector.control.buttons[key].label === field_name) {
          if (enabled) {
              $grid.topToolbar.field_selector.control.buttons[key].icon = 'icon icon-ic_fluent_checkmark_circle_20_regular';
              $grid.topToolbar.field_selector.control.buttons[key].styles.setCreationClass();
          } else {
              $grid.topToolbar.field_selector.control.buttons[key].styles.clearClasses();
              $grid.topToolbar.field_selector.control.buttons[key].icon = 'icon icon-ic_fluent_circle_20_regular';
          }
      }
  }
  
  $event.preventDefault();
  $event.stopPropagation();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
