import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';


@Injectable({ providedIn: 'root' })
export class Invoices_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
  }

    public Utilities: Utilities_OperationService;
  public Invoices: Invoices_OperationService = this;

  public Disable_Invoice_ManualStatusChange = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Invoice_ManualStatusChange');
    }
  }
  public Enable_AutoInvoicing = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_AutoInvoicing');
    }
  }
  public Enable_AutoInvoicing_ManualExecution = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_AutoInvoicing_ManualExecution');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/Invoices/operations/${operationName}/isauthorized`, null);
  }
}
