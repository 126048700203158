import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';


@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
}

