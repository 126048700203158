<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="process" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="complete" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_complete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="appointment" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_appointment_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator8" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="on_delete" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator2" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="on_print" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.receiving_report.readOnly"
                            [ngStyle]="tool.control.buttons.receiving_report.styles.style"
                            [ngClass]="tool.control.buttons.receiving_report.styles.classes"
                            (click)="on_receiving_report_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.receiving_report.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.receiving_report.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.receiving_report.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.po_label.readOnly"
                            [ngStyle]="tool.control.buttons.po_label.styles.style"
                            [ngClass]="tool.control.buttons.po_label.styles.classes"
                            (click)="on_po_labels_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.po_label.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.po_label.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.po_label.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_class.label}}{{fields.order_class.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_class.label}}">{{fields.order_class.label}}<span *ngIf="fields.order_class.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <TransloadOrders-orderclasses_dd_single 
                            data-cy="selector"
                            [type]="fields.order_class.control.type"
                            formControlName="order_class"
                            (displayTextChange)="fields.order_class.control.displayText=$event"
                            [placeholder]="fields.order_class.control.placeholder"
                            [styles]="fields.order_class.control.styles"
                        >
                        </TransloadOrders-orderclasses_dd_single>
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_status" *ngIf="!fields.order_status.hidden" 
                            class="field-container standard {{fields.order_status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_status.styles.style"
                            [ngClass]="fields.order_status.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_status.label}}{{fields.order_status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_status.label}}">{{fields.order_status.label}}<span *ngIf="fields.order_status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.order_status.control.styles.style"
                              [ngClass]="fields.order_status.control.styles.classes">{{fields.order_status.control.text }}</div>
                        <ng-container *ngIf="fields.order_status.invalid">
                          <ng-container *ngFor="let error of fields.order_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-inbound_details"
                     *ngIf="!fieldsets.inbound_details.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.inbound_details.collapsible }">
                    <div *ngIf="!fieldsets.inbound_details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.inbound_details.toggle()">
                      <span class="fieldsetsTitle-text">Inbound details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.inbound_details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.inbound_details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.inbound_details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.inbound_details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-inbound_vendor_reference" *ngIf="!fields.inbound_vendor_reference.hidden" 
                            class="field-container standard {{fields.inbound_vendor_reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.inbound_vendor_reference.styles.style"
                            [ngClass]="fields.inbound_vendor_reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.inbound_vendor_reference.label}}{{fields.inbound_vendor_reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.inbound_vendor_reference.label}}">{{fields.inbound_vendor_reference.label}}<span *ngIf="fields.inbound_vendor_reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="inbound_vendor_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.inbound_vendor_reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.inbound_vendor_reference.control.placeholder}}"
                                [ngStyle]="fields.inbound_vendor_reference.control.styles.style"
                                [ngClass]="fields.inbound_vendor_reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.inbound_vendor_reference.invalid">
                          <ng-container *ngFor="let error of fields.inbound_vendor_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inbound_owner_reference" *ngIf="!fields.inbound_owner_reference.hidden" 
                            class="field-container standard {{fields.inbound_owner_reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.inbound_owner_reference.styles.style"
                            [ngClass]="fields.inbound_owner_reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.inbound_owner_reference.label}}{{fields.inbound_owner_reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.inbound_owner_reference.label}}">{{fields.inbound_owner_reference.label}}<span *ngIf="fields.inbound_owner_reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="inbound_owner_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.inbound_owner_reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.inbound_owner_reference.control.placeholder}}"
                                [ngStyle]="fields.inbound_owner_reference.control.styles.style"
                                [ngClass]="fields.inbound_owner_reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.inbound_owner_reference.invalid">
                          <ng-container *ngFor="let error of fields.inbound_owner_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inbound_conveyance" *ngIf="!fields.inbound_conveyance.hidden" 
                            class="field-container standard {{fields.inbound_conveyance.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.inbound_conveyance.styles.style"
                            [ngClass]="fields.inbound_conveyance.styles.classes">
                        <div class="label-container"
                              title="{{fields.inbound_conveyance.label}}{{fields.inbound_conveyance.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.inbound_conveyance.label}}">{{fields.inbound_conveyance.label}}<span *ngIf="fields.inbound_conveyance.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <TransloadOrders-conveyances_dd_single 
                            data-cy="selector"
                            [type]="fields.inbound_conveyance.control.type"
                            formControlName="inbound_conveyance"
                            (displayTextChange)="fields.inbound_conveyance.control.displayText=$event"
                            [placeholder]="fields.inbound_conveyance.control.placeholder"
                            [styles]="fields.inbound_conveyance.control.styles"
                        >
                        </TransloadOrders-conveyances_dd_single>
                        <ng-container *ngIf="fields.inbound_conveyance.invalid">
                          <ng-container *ngFor="let error of fields.inbound_conveyance.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inbound_conveyance_reference" *ngIf="!fields.inbound_conveyance_reference.hidden" 
                            class="field-container standard {{fields.inbound_conveyance_reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.inbound_conveyance_reference.styles.style"
                            [ngClass]="fields.inbound_conveyance_reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.inbound_conveyance_reference.label}}{{fields.inbound_conveyance_reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.inbound_conveyance_reference.label}}">{{fields.inbound_conveyance_reference.label}}<span *ngIf="fields.inbound_conveyance_reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="inbound_conveyance_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.inbound_conveyance_reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.inbound_conveyance_reference.control.placeholder}}"
                                [ngStyle]="fields.inbound_conveyance_reference.control.styles.style"
                                [ngClass]="fields.inbound_conveyance_reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.inbound_conveyance_reference.invalid">
                          <ng-container *ngFor="let error of fields.inbound_conveyance_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inbound_notes" *ngIf="!fields.inbound_notes.hidden" 
                            class="field-container double {{fields.inbound_notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.inbound_notes.styles.style"
                            [ngClass]="fields.inbound_notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.inbound_notes.label}}{{fields.inbound_notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.inbound_notes.label}}">{{fields.inbound_notes.label}}<span *ngIf="fields.inbound_notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="inbound_notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.inbound_notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.inbound_notes.control.placeholder}}"
                                [ngStyle]="fields.inbound_notes.control.styles.style"
                                [ngClass]="fields.inbound_notes.control.styles.classes"> 
                        <ng-container *ngIf="fields.inbound_notes.invalid">
                          <ng-container *ngFor="let error of fields.inbound_notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.expected_licenseplate_count.hidden" class="widget-container">
            <TransloadOrders-order_expected_licenseplate_count_widget
            #$widgets_expected_licenseplate_count 
              [orderId]="$widgets_expected_licenseplate_count_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_expected_licenseplate_count')"
            >
            </TransloadOrders-order_expected_licenseplate_count_widget>
          </div>
          <div *ngIf="!widgets.crossdocked_licenseplate_count.hidden" class="widget-container">
            <TransloadOrders-order_crossdocked_licenseplate_count_widget
            #$widgets_crossdocked_licenseplate_count 
              [shipmentIds]="$widgets_crossdocked_licenseplate_count_inParams_shipmentIds"
            ($refreshEvent)="refresh(false, false, '$widgets_crossdocked_licenseplate_count')"
            >
            </TransloadOrders-order_crossdocked_licenseplate_count_widget>
          </div>
          <div *ngIf="!widgets.loaded_licenseplate_count.hidden" class="widget-container">
            <TransloadOrders-order_loaded_licenseplate_count_widget
            #$widgets_loaded_licenseplate_count 
              [shipmentIds]="$widgets_loaded_licenseplate_count_inParams_shipmentIds"
            ($refreshEvent)="refresh(false, false, '$widgets_loaded_licenseplate_count')"
            >
            </TransloadOrders-order_loaded_licenseplate_count_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.purchase_order_lines.hidden" class="tab" data-cy="tab-purchase_order_lines">
              <h2 [className]="tabs.purchase_order_lines.active? 'active': ''" (click)="tabs.purchase_order_lines.activate()">{{tabs.purchase_order_lines.title}}</h2>
            </div>
            <div *ngIf="!tabs.receiving_tasks.hidden" class="tab" data-cy="tab-receiving_tasks">
              <h2 [className]="tabs.receiving_tasks.active? 'active': ''" (click)="tabs.receiving_tasks.activate()">{{tabs.receiving_tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.received_inventory.hidden" class="tab" data-cy="tab-received_inventory">
              <h2 [className]="tabs.received_inventory.active? 'active': ''" (click)="tabs.received_inventory.activate()">{{tabs.received_inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.outbound_shipments.hidden" class="tab" data-cy="tab-outbound_shipments">
              <h2 [className]="tabs.outbound_shipments.active? 'active': ''" (click)="tabs.outbound_shipments.activate()">{{tabs.outbound_shipments.title}}</h2>
            </div>
            <div *ngIf="!tabs.contacts.hidden" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
            <div *ngIf="!tabs.billing.hidden" class="tab" data-cy="tab-billing">
              <h2 [className]="tabs.billing.active? 'active': ''" (click)="tabs.billing.activate()">{{tabs.billing.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-transload_order_lines_grid *ngIf="tabs.purchase_order_lines.active"
              #$tabs_purchase_order_lines
              [orderId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_purchase_order_lines')">
              </FootPrintManager-transload_order_lines_grid>
              <FootPrintManager-transload_order_receiving_grid *ngIf="tabs.receiving_tasks.active"
              #$tabs_receiving_tasks
              [orderId]="$tabs_receiving_tasks_transload_order_receiving_grid_inParams_orderId"
              [shipmentId]="$tabs_receiving_tasks_transload_order_receiving_grid_inParams_shipmentId"
              [orderStatusId]="$tabs_receiving_tasks_transload_order_receiving_grid_inParams_orderStatusId"
              [transload_id]="$tabs_receiving_tasks_transload_order_receiving_grid_inParams_transload_id"
              ($refreshEvent)="refresh(false, false, '$tabs_receiving_tasks')">
              </FootPrintManager-transload_order_receiving_grid>
              <FootPrintManager-transload_order_received_inventory_grid *ngIf="tabs.received_inventory.active"
              #$tabs_received_inventory
              [orderId]="$tabs_received_inventory_transload_order_received_inventory_grid_inParams_orderId"
              [shipmentId]="$tabs_received_inventory_transload_order_received_inventory_grid_inParams_shipmentId"
              [transload_id]="$tabs_received_inventory_transload_order_received_inventory_grid_inParams_transload_id"
              ($refreshEvent)="refresh(false, false, '$tabs_received_inventory')">
              </FootPrintManager-transload_order_received_inventory_grid>
              <FootPrintManager-transload_outbound_shipments_grid *ngIf="tabs.outbound_shipments.active"
              #$tabs_outbound_shipments
              [orderId]="$tabs_outbound_shipments_transload_outbound_shipments_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_outbound_shipments')">
              </FootPrintManager-transload_outbound_shipments_grid>
              <FootPrintManager-orderaddresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [orderId]="$tabs_contacts_orderaddresses_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootPrintManager-orderaddresses_grid>
              <ng-container
                *ngIf="tabs.billing.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.billing.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.billing.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.billing.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-accessorial_tasks_grid
                   *ngIf="tabs.billing.tabs.accessorial_tasks.active"
                  #$tabs_billing_accessorial_tasks
                  [projectId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId"
                  [entityStatusId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId"
                  [orderId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId"
                  [warehouseId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_accessorial_tasks')">
                  </FootPrintManager-accessorial_tasks_grid>
                  <FootPrintManager-billing_records_grid
                   *ngIf="tabs.billing.tabs.billing_records.active"
                  #$tabs_billing_billing_records
                  [orderIds]="$tabs_billing_billing_records_billing_records_grid_inParams_orderIds"
                  [includeInvoiced]="$tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced"
                  [shipmentIds]="$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_billing_records')">
                  </FootPrintManager-billing_records_grid>
              </ng-container>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>