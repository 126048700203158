import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';


import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
    this.FootPrintManager = this.injector.get(FootPrintManager_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public Notifications: Notifications_FlowService;
    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Invoices: Invoices_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
    public FootPrintManager: FootPrintManager_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
}
